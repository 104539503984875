import Vue from 'vue';

export default {
  state: {
    loader: false,
    cloudinaryName: '',
    cloudinaryUploadPreset: '',
    cloudinaryKey: '',
    handzinURL: '',
    intivaSSoURL: '',
    enchancedPriceId: '',
    premierPriceId: '',
  },
  mutations: {
    mutate(state, payload) {
      state[payload.property] = payload.with
    }
  },
  actions: {
    fetchSecretKeys({ commit }) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get('/api/xchange/env_vars')
        .then((res) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          commit('mutate', {
            property: 'cloudinaryName',
            with: res.data.cloudinary_name
          })
          commit('mutate', {
            property: 'cloudinaryUploadPreset',
            with: res.data.cloudinary_upload_preset
          })
          commit('mutate', {
            property: 'cloudinaryKey',
            with: res.data.cloudinary_key
          })
          commit('mutate', {
            property: 'intivaSSoURL',
            with: res.data.intiva_sso_url
          })
          commit('mutate', {
            property: 'handzinURL',
            with: res.data.handzin_sso_url
          })
          commit('mutate', {
            property: 'enchancedPriceId',
            with: res.data.enhanced_price_id
          })
          commit('mutate', {
            property: 'premierPriceId',
            with: res.data.premier_price_id
          })
          resolve(res)
        })
        .catch((err) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          reject(err);
        })
      })
    }
  }
}