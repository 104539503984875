import Vue from 'vue';
import Router from 'vue-router'
import { authGuard } from '../auth';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/account-confirmed',
      redirect: to => {
        return {path: '/', query: { q: to.query} }
      }
    },

    {
      path: '/',
      component: () => import('@/layouts/Default.vue'),
      beforeEnter: authGuard,
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/views/Profile.vue'),
        },

        {
          path: 'booking',
          name: 'booking',
          component: () => import('@/views/Booking.vue')
        },

        {
          path: 'hiring',
          name: 'hiring',
          component: () => import('@/views/Hiring.vue')
        },

        {
          path: 'admin',
          name: 'admin',
          component: () => import('@/views/Admin.vue')
        },

        {
          path: 'company-profile',
          name: 'company-profile',
          component: () => import('@/views/CompanyProfile.vue')
        },

        {
          path: 'update-company-profile',
          name: 'update-company-profile',
          component: () => import('@/views/UpdateCompanyProfile.vue')
        },

        {
          path: 'find-providers',
          name: 'find-providers',
          component: () => import('@/views/Providers.vue')
        }
      ]
    }
  ]
});

export default router;
