import Vue from 'vue';
import Vuex from 'vuex';
import profile from './profile'
import secretKeys from './env'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    profile,
    secretKeys
  },
  state: {}
})