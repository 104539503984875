import http from './axios'

export default {
  install: Vue => {
    Vue.prototype.$http = http;
    Vue.$http = Vue.prototype.$http;

    Vue.prototype.$http.interceptors.response.use(
      response => {
        return {
          ...response,
          statusCode: response.status,
          statusText: response.statusText
        }
      },
      error => {
        return Promise.reject({
          ...error,
          statusCode: error.response.status,
          statusText: error.response.statusText
        })
      }
    )
  }
}