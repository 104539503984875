import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from "./store";
import KProgress from 'k-progress';
import VCalendar from 'v-calendar';
import { Auth0Plugin } from "./auth";
import { domain, clientId } from "../auth_config.json";
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import appPlugins from './helpers/plugins'
import Cloudinary, { CldImage, CldTransformation, CldPlaceholder } from "cloudinary-vue";

import './assets/css/base.css'
import './assets/css/main.css'
import 'element-ui/lib/theme-chalk/index.css';

// configure language
locale.use(lang)

// import ElementUi components on demand
import {
  Form,
  FormItem,
  TimeSelect,
  Select,
  Option,
  OptionGroup,
  TimePicker,
  Input,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  DatePicker,
  Dialog,
  Button,
  Table,
  TableColumn,
  MessageBox,
  Message,
} from 'element-ui';

Vue.config.productionTip = false

Vue.use(appPlugins)

Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;

Vue.component('k-progress', KProgress);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Input);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(DatePicker);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

Vue.use(Cloudinary, {
  configuration: { cloudName: "vitel-health" },
  components: {
    CldImage,
    CldTransformation,
    CldPlaceholder,
  }
})

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
