import Vue from 'vue';

export default {
  state : {
    loader: false,
  },
  mutations: {
    mutate(state, payload) {
      state[payload.property] = payload.with
    }
  },
  actions: {
    updateProfilePicture({ commit }, data) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.patch(`/api/xchange/profile/picture`, null, {
          params: {
            user_id: data.user_id,
            image_url: data.image_url
          }
        })
        .then((res) => {
          console.log('===>', res);
          commit('mutate', {
            property: 'loader',
            with: false
          })
          resolve(res)
        })
        .catch((err) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          reject(err)
        })
      })
    },
    updatePassword({ commit }, data) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.patch(`/api/xchange/profile/password`, null, {
          params: {
            email: data.email,
            current_password: data.current_password,
            new_password: data.new_password
          }
        })
        .then((res) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          resolve(res)
        })
        .catch((err) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          reject(err)
        })
      })
    },
    fetchCompanyProfile({ commit }, user_id) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`/api/vip/client`, {
          params: {
            ...user_id
          }
        })
        .then((response) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          resolve(response)
        })
        .catch((err) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          reject(err)
        })
      })
    },
    updateCompanyProfile({ commit }, data) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.post(`/api/vip/client/create`, null, {
          params: {
            name: data.name,
            logo_url: data.logo_url,
            website: data.website,
            description: data.description,
            hq_address: data.hq_address,
            user_id: data.user_id
          }
        })
        .then((res) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          resolve(res)
        })
        .catch((err) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          reject(err)
        })
      })
    },
    createJobPosting({ commit }, data) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.post(`/api/vip/client/job/create`, data)
        .then((res) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          resolve(res);
        })
        .catch((err) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          reject(err)
        })
      })
    },
    fetchSpecialties({ commit }) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`api/booking_search/specialties`)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)
          })
      })
    }
  }
}